import GhostContentAPI from "@tryghost/content-api"

// Create API instance with site credentials

/*
const api = new GhostContentAPI({
  url: 'http://localhost:2368',
  key: '431639459ff183340a8aa30995',
  version: "v2"
});
*/

const api = new GhostContentAPI({
  url: "https://blog.s-sofin.com",
  key: "ac8264ace10baa5068fca9cd8c",
  version: "v2",
})

export async function getPosts() {
  return await api.posts
    .browse({
      limit: "all",
      include: "tags,authors",
    })
    .catch((err) => {
      console.error(err)
    })
}

export async function getSinglePost(postSlug) {
  return await api.posts
    .read({
      slug: postSlug,
      include: "tags,authors",
    })
    .catch((err) => {
      console.error(err)
    })
}

export async function getPostbyTag(tag) {
  return await api.posts
    .browse({
      limit: "all",
      filter: tag,
      include: "tags,authors",
    })
    .catch((err) => {
      console.error(err)
    })
}
