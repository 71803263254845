<template>
  <div class="site-wrap">
    <Header />

    <div
      v-if="vdoEnd"
      class="site-blocks-cover"
      style="height: auto; background-color: #ffce00"
    >
      <img
        alt="hero-banner-home"
        class="hero-banner-home"
        src="@/assets/HOME.png"
      />
      <img
        alt="hero-banner-mobile"
        class="hero-banner-mobile"
        src="@/assets/HOME_mobile.png"
      />
    </div>

    <div
      v-if="!vdoEnd"
      id="player"
      class="vdo-player"
      style="z-index: 100; top: 100px; left: 100px; background-color: #ffce00"
    >
      <video
        class="vdo"
        autoplay
        muted
        playsinline
        preload="auto"
        style="width: 100%"
        @ended="onPlayerEnded"
      >
        <source
          src="https://www.s-sofin.com/vdo_landing.mp4"
          type="video/mp4"
          @stalled="onPlayerError"
          @error="onPlayerError"
          @abort="onPlayerError"
          @emptied="onPlayerError"
        />
        Your browser does not support the video tag.
      </video>
    </div>

    <!-- <div class="banner-txt">UNDER CONSTRUCTION</div> -->
    <!-- 
    <section
      class="site-section"
      style="background-color: #ffffff; padding-bottom: 0"
    >
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-md-8 text-center">
            <h2
              class="h1 site-section-heading text-center"
              style="color: #000000"
            >
              {{ $t("home.vision") }}
              <h4 style="color: black"></h4>
            </h2>
          </div>
        </div>
        <img
          alt="our-inspiration"
          src="@/assets/our-inspiration.png"
          style="width: 100%; padding: 0"
        />
      </div>
    </section>

    <section class="site-section" style="background-color: #f0f0f0">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-md-8 text-center">
            <h2
              class="h1 site-section-heading text-center"
              style="color: #000000"
            >
              {{ $t("home.value") }}
              <h4 style="color: black"></h4>
            </h2>
          </div>
        </div>
        <img
          alt="value-propositions"
          src="@/assets/value-propositions.png"
          style="width: 100%; padding: 0"
        />
      </div>
    </section> -->

    <section class="site-section iss" style="background-color: #ffce00">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-md-8 text-center">
            <h2
              class="h1 site-section-heading text-center"
              style="color: #000000"
            >
              ISSS
              <h4 style="color: black">
                International Standard System Security
              </h4>
            </h2>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-xs-12 col-md-6 col-lg-6 text-center">
            <img alt="it-security" class="iso" src="@/assets/iso27001.png" />
          </div>
          <div class="col-xs-12 col-md-6 col-lg-6 text-center">
            <img
              alt="iso27001"
              class="iso"
              style="margin-top: 20px"
              src="@/assets/ISO27001_logo.png"
            />
          </div>
        </div>

        <!-- <div class="grid-container">
          <div class="grid-row">
            <div class="grid-item">
              <div class="grid-item-wrapper">
                <div class="grid-item-container">
                  <div class="grid-item-content">
                    <span class="item-title"> RISK MANAGEMENT </span>
                    <span class="item-category">
                      Helps organizations identify, analyze and evaluate
                      weaknesses in their information security processes.
                    </span>
                    <span class="more-info">
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Asset Inventory <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Risk Assessment <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Risk Treatment
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-item">
              <div class="grid-item-wrapper">
                <div class="grid-item-container">
                  <div class="grid-item-content">
                    <span class="item-title"> Process </span>
                    <span class="item-category">
                      Defined procedures, work instructions and
                      controlled processes to fulfil risk management objectives.
                    </span>
                    <span class="more-info">
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Forms <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Procedures <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Work Instructions
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-item">
              <div class="grid-item-wrapper">
                <div class="grid-item-container">
                  <div class="grid-item-content">
                    <span class="item-title"> Execution </span>
                    <span class="item-category">
                      Execute processes for risk assessment and risk treatment
                    </span>
                    <span class="more-info">
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      DRP Testing <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Penetration Testing <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Training <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Closing Gaps <br />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-item">
              <div class="grid-item-wrapper">
                <div class="grid-item-container">
                  <div class="grid-item-content">
                    <span class="item-title"> Evidence </span>
                    <span class="item-category">
                      Collects information and testing logs
                    </span>
                    <span class="more-info">
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Records <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Reports
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-item">
              <div class="grid-item-wrapper">
                <div class="grid-item-container">
                  <div class="grid-item-content">
                    <span class="item-title"> Internal Audit </span>
                    <span class="item-category">
                      Involves a thorough examination of your organisation’s
                      ISMS to ensure that it meets the Standard’s requirements.
                    </span>
                    <span class="more-info">
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Corrective Actions <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Audit Finding <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Audit Plan
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-item">
              <div class="grid-item-wrapper">
                <div class="grid-item-container">
                  <div class="grid-item-content">
                    <span class="item-title"> Evaluation </span>
                    <span class="item-category">
                      Evaluate how the ISMS is performing and how effective the
                      information security management system is
                    </span>
                    <span class="more-info">
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Measurement <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      KPI
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-item">
              <div class="grid-item-wrapper">
                <div class="grid-item-container">
                  <div class="grid-item-content">
                    <span class="item-title"> Review </span>
                    <span class="item-category">
                      Continuous review and assess of actions and results.
                    </span>
                    <span class="more-info">
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Management Review <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Action After Review <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Lessons Learned
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid-item">
              <div class="grid-item-wrapper">
                <div class="grid-item-container">
                  <div class="grid-item-content">
                    <span class="item-title"> Management Policy </span>
                    <span class="item-category">
                      Issue risk policies and risk guidelines to be enforced in
                      the organisation
                    </span>
                    <span class="more-info">
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Information Security Policy <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      ISMS <br />
                      <img alt="check-logo" src="@/assets/icons-checked.svg" />
                      Statement of Applicability
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import { getPosts } from "../api/posts"

export default {
  props: ["post"],
  name: "Home",
  data: () => ({
    posts: [],
    limit: 3,
    vdoEnd: false,
  }),
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.asyncData()
  },
  methods: {
    async asyncData() {
      const result = await getPosts()
      console.log(result)
      this.posts = result
    },
    onPlayerEnded(player) {
      // console.log('player ended!', player)
      this.vdoEnd = true
    },
    onPlayerError(player) {
      // console.log('player ended!', player)
      this.vdoEnd = true
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text) {
        return text.substring(0, length) + suffix
      }
    },
  },
  computed: {
    computedObj() {
      return this.limit ? this.posts.slice(0, this.limit) : this.posts
    },
    player() {
      return this.$refs.videoPlayer.player
    },
  },
}
</script>

<style lang="scss">
.banner-txt {
  font-size: 90px;
  font-weight: 700;
  color: #333333;
  z-index: 100;
  @media (max-width: 555px) {
    margin-top: 0px;
    font-size: 40px;
  }
}
.iso {
  max-width: 300px;
  width: auto;
  height: auto;
  vertical-align: middle;

  @media (max-width: 555px) {
    padding: 1rem 1rem;
  }
}

.media-1 {
  border-radius: 20px;
}

.hero-banner {
  width: 80%;
  justify-content: center;
  padding: 5rem 10px;

  @media (max-width: 576px) {
    display: none;
  }
}

.hero-banner-home {
  width: 102%;
  justify-content: center;

  @media (max-width: 576px) {
    display: none;
  }
}

.hero-banner-mobile {
  display: none;
  height: 0px;

  @media (max-width: 576px) {
    width: 100%;
    padding: 0;
    height: auto;
    justify-content: center;
    display: initial;
  }
}

h1 {
  color: #ffce00;
}

.h-entry {
  text-align: left;
}

.meta {
  color: black 0.35;
}

a,
a:visited {
  color: black;
}

.container {
  @media (max-width: 576px) {
    .site-section-heading {
      padding: 0;
      // margin: 0;
    }
  }
}

.site-blocks-cover {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.iss {
  background: repeating-linear-gradient(
    90deg,
    rgb(255, 191, 0),
    rgb(255, 191, 0) 3px,
    #ffce00 3px,
    #ffce00 20px
  );
}

.grid-container {
  max-width: 1335px;
  margin: 0 auto;
}

.grid-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.grid-item {
  // height: 550px;
  -ms-flex: auto;
  // width: 400px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  flex-basis: 30%;

  @media (max-width: 1333px) {
    flex-basis: 33.33%;
  }

  @media (max-width: 1073px) {
    flex-basis: 33.33%;
  }

  @media (max-width: 815px) {
    flex-basis: 50%;
  }

  @media (max-width: 555px) {
    flex-basis: 50%;
  }
}

.grid-item-wrapper {
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
  background: transparent;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  -webkit-transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    margin 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: padding 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    margin 0.15s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.grid-item-container {
  height: 100%;
  width: 100%;
  position: relative;
  background: #ffffff;
  border-radius: 10px;
}

.grid-item-content {
  padding: 0 20px 20px 20px;
}

.item-title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  padding: 1rem 0px;
  display: block;

  @media (max-width: 555px) {
    min-height: 80px;
  }
}

.item-category {
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  min-height: 90px;

  @media (max-width: 555px) {
    font-size: 12px;
    min-height: 60px;
  }
}

.item-excerpt {
  margin-bottom: 20px;
  display: block;
  font-size: 12px;
}

.more-info {
  bottom: 0;
  padding-left: 0;
  transition-duration: 0.5s;
  font-size: 14px;
  display: block;
  text-align: left;

  img {
    padding: 0 2px;
  }

  @media (max-width: 555px) {
    font-size: 12px;
    width: 150%;
    margin-left: -20px;
  }
}

.more-info i {
  padding-left: 5px;
  transition-duration: 0.5s;
}

.grid-item:hover .more-info i {
  padding-left: 20px;
  transition-duration: 0.5s;
}

.more-info i::before {
  font-size: 16px;
}

.grid-item:hover .grid-item-wrapper {
  padding: 2% 2%;
  margin: -2% -2%;
}
</style>
