<template>
  <div class="site-wrap">
    <Header />

    <div class="site-blocks-cover" style="height: auto">
      <img class="hero-banner-home" src="@/assets/VLOG.svg" />

      <img class="hero-banner-mobile" src="@/assets/VLOG.svg" />
    </div>

    <section class="site-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row mb-5">
              <div
                class="col-md-4 col-lg-4 mb-4 mb-lg-4"
                v-for="post in itemPosts"
                :key="post.id"
                :post="post"
              >
                <div class="h-entry">
                  <a :href="post.slug">
                    <img
                      :src="post.feature_image"
                      alt="Image"
                      class="img-fluid"
                    />

                    <h2 class="font-size-regular">{{ post.title }}</h2>
                  </a>

                  <div class="meta mb-4">
                    {{ post.authors[0].name }}
                    <span class="mx-2">&bullet;</span>
                    {{
                      post.created_at
                        .replace("T", " ")
                        .replace(".000+07:00", "") || ""
                    }}
                    <span class="mx-2">&bullet;</span>
                  </div>

                  <p>{{ post.excerpt | truncate(200, "...") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container-page">
      <pagination
        :records="posts.length"
        v-model="page"
        :per-page="itemsPerPage"
      >
      </pagination>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import { getPosts, getPostbyTag } from "../api/posts"

export default {
  props: ["post"],
  name: "Vlog",
  data: () => ({
    posts: [],
    itemPosts: [],
    itemsPerPage: 6,
    page: 1,
  }),
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.asyncData()
  },
  watch: {
    page() {
      let x = Number((this.page - 1) * this.itemsPerPage)
      console.log(
        `Page ${this.page} was selected. ${x} - ${x + this.itemsPerPage}`
      )
      this.itemPosts = this.posts.slice(x, x + this.itemsPerPage)
    },
  },
  methods: {
    async asyncData() {
      // this.posts = await getPosts()
      this.posts = await getPostbyTag("tag:vlog")
      // console.log(this.posts)
      this.itemPosts = this.posts.slice(0, this.itemsPerPage)
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text) {
        return text.substring(0, length) + suffix
      }
    },
  },
}
</script>

<style lang="scss">
.VuePagination {
  ul {
    margin: 0 auto;
  }
  li {
    margin-left: 5px;
    a {
      background: #fece00;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      color: white;
    }
    .active {
      background: #303030 !important;
      filter: drop-shadow(2px 4px 6px #303030);
    }
  }
  .active {
    background: unset;
  }
  .page-item.active .page-link {
    color: white !important;
  }
  .VuePagination__count {
    margin-top: 20px;
  }
}
p {
  overflow-wrap: break-word;
}
.container-page {
  display: flex;
  justify-content: center;
}
</style>
