import Vue from "vue"
import VueRouter from "vue-router"

// const Home = () => import('@/views/Home')
// const Blog = () => import('@/views/Blog')
// const Vlog = () => import('@/views/Vlog')
// const Work = () => import('@/views/Work')
// const Contact = () => import('@/views/Contact')
// const Post = () => import('@/views/_slug')

import Home from "../views/Home.vue"
import Blog from "../views/Blog.vue"
import Vlog from "../views/Vlog.vue"
import Work from "../views/Work.vue"
import Contact from "../views/Contact.vue"
import Policy from "../views/Policy.vue"
import Post from "../views/_slug.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/vlog",
    name: "vlog",
    component: Vlog,
  },
  {
    path: "/work",
    name: "work",
    component: Work,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/Policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/:slug",
    name: "post",
    component: Post,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
