import Vue from "vue"
import App from "./App.vue"
import router from "./router"
// import store from './store'
import i18n from "./i18n.js"
import store from "./store/store.js"

import VueI18n from "vue-i18n"

import { LMap, LTileLayer, LMarker } from "vue2-leaflet"
import { Icon } from "leaflet"
import "leaflet/dist/leaflet.css"

import VueGtag from "vue-gtag"
// import './registerServiceWorker'

Vue.use(VueGtag, {
  config: { id: "UA-121530091-8" },
})

import Pagination from "vue-pagination-2"
Vue.component("pagination", Pagination)

Vue.component("l-map", LMap)
Vue.component("l-tile-layer", LTileLayer)
Vue.component("l-marker", LMarker)

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
})

Vue.use(VueI18n)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
