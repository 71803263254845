<template>
  <section>
    <Header />
    <article class="content-box">
      <h1 class="post-title"> {{ post.title }} </h1>
      <div class="meta mb-4" style="text-align: left; padding: 0 1rem;">
        {{ author }}
        <span class="mx-2">&bullet;</span>
        {{ date }}
        <span class="mx-2">&bullet;</span> </div>
      <div v-html="post.html"/>
    </article>
    <Footer />
  </section>
</template>

<script>
import { getSinglePost } from '../api/posts';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components:{
    Header,
    Footer
  },
  data: () => ({
    post: [],
    author: null,
    date: null
  }),
  mounted () {
    this.asyncData()
  },
  methods: {
    async asyncData () {
      getSinglePost(this.$route.params.slug).then(result => {
        this.post = result
        console.log(result);
        this.date = this.post.created_at.replace('T',' ').replace('.000+07:00','')
        this.author = this.post.authors[0].name
      })
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
        return text.substring(0, length) + suffix
    }
  },
}
</script>

<style lang="scss">
.content-box {
  padding: 2rem 0rem;

  .post-title {
    font-size: 32px;
    color: #000000;
    text-align: left;
    padding: 1rem 1rem;
  }

  p {
    padding: 0rem 1rem;
    text-align: left;
  }

  @media (min-width: 576px) {
    padding: 2rem 10rem;
  }

  @media (min-width: 1200px) {
    padding: 2rem 20rem;
  }

  img {
    max-width: 400px;
  }
}

strong {
  font-size: 20px;
  font-weight: 700;
}

</style>