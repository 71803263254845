<template>
  <div class="site-wrap">
    <Header />

    <div class="site-blocks-cover" style="height: 550px">
      <!-- <img class="hero-banner-home" src="@/assets/contact-background.jpg" />
      <img class="hero-banner-mobile" src="@/assets/contact-mobile-background.jpg" /> -->

      <l-map :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="marker"></l-marker>
      </l-map>
    </div>

    <div class="site-section bg-light">
      <div class="container">
        <div class="row">
          <!-- <div class="col-md-7 mb-5" style="min-height: 400px">
            <l-map :zoom="zoom" :center="center">
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              <l-marker :lat-lng="marker"></l-marker>
            </l-map>
          </div> -->
          <div class="col-md-6">
            <div class="p-4 mb-3 bg-white">
              <p class="mb-0 font-weight-bold">{{ $t("contact.office") }}</p>
              <p class="mb-4">
                {{ $t("contact.addr1") }} <br />
                {{ $t("contact.addr2") }} <br />
                {{ $t("contact.addr3") }}
              </p>

              <p class="mb-0 font-weight-bold">{{ $t("contact.phone") }}</p>
              <p class="mb-4">
                <a href="tel://+6620580751"> +662 058 0751 </a>
              </p>

              <p class="mb-0 font-weight-bold">E-Mail</p>
              <p class="mb-4">
                <a href="mailto:support_ssofin@segroup.co.th">
                  support_ssofin@segroup.co.th
                </a>
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-4 mb-3 bg-white">
              <p class="mb-0 font-weight-bold">Head Quarter</p>
              <p class="mb-4">
                {{ $t("contact.hqaddr1") }} <br />
                {{ $t("contact.hqaddr2") }} <br />
                {{ $t("contact.hqaddr3") }}
              </p>

              <p class="mb-0 font-weight-bold">Phone</p>
              <p class="mb-4">
                <a href="tel://+6620580751"> +662 058 0751 </a>
              </p>

              <p class="mb-0 font-weight-bold">E-Mail</p>
              <p class="mb-4">
                <a href="mailto:support_ssofin@segroup.co.th">
                  support_ssofin@segroup.co.th
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"

export default {
  name: "Contact",
  data: () => ({
    zoom: 13,
    center: L.latLng(13.856071, 100.640237),
    url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    marker: L.latLng(13.856071, 100.640237),
  }),
  components: {
    Header,
    Footer,
  },
  mounted() {},
  methods: {},
  filters: {},
}
</script>

<style></style>
