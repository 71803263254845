<template>
  <footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-9" style="margin-left: auto; margin-right: auto">
          <div class="row">
            <div class="col-md-6">
              <h2 class="footer-heading mb-4">{{ $t("footer.footer1") }}</h2>
              <p>{{ $t("footer.history") }}</p>
            </div>
            <!-- <div class="col-md-3 ml-auto">
                <h2 class="footer-heading mb-4"> {{ $t('footer.footer2') }} </h2>
                <ul class="list-unstyled">
                  <li><a href="/"> {{ $t('nav.home') }} </a></li>
                  <li><a href="/Work"> {{ $t('nav.work') }} </a></li>
                  <li><a href="/Blog"> {{ $t('nav.blog') }} </a></li>
                  <li><a href="/Vlog"> {{ $t('nav.vlog') }} </a></li>
                  <li><a href="/Contact"> {{ $t('nav.contact') }} </a></li>
                  <li><a href="/Policy"> {{ $t('nav.policy') }} </a></li>
                </ul>
              </div> -->
            <div class="col-md-3">
              <h2 class="footer-heading mb-4">{{ $t("footer.footer3") }}</h2>
              <a
                href="https://www.facebook.com/S-Sofin-101677661276960/"
                class="pl-0 pr-3"
              >
                <!-- <span class="icon-facebook"></span> -->
                <img alt="fb-logo" src="@/assets/icons/facebook.png" />
              </a>
              <a href="#" class="pl-3 pr-3">
                <!-- <span class="icon-twitter"></span> -->
                <img alt="twitter-logo" src="@/assets/icons/twitter.png" />
              </a>
              <a href="#" class="pl-3 pr-3">
                <!-- <span class="icon-instagram"></span> -->
                <img alt="ig-logo" src="@/assets/icons/instagram.png" />
              </a>
              <a href="#" class="pl-3 pr-3">
                <!-- <span class="icon-linkedin"></span> -->
                <img alt="line-logo" src="@/assets/icons/line.png" />
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3">
            <h2 class="footer-heading mb-4">Subscribe Us</h2>
            <form action="#" method="post">
              <div class="input-group mb-3">
                <input type="text" class="form-control border-secondary text-white bg-transparent" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2">
                <div class="input-group-append">
                  <button class="btn btn-warning text-black" type="button" id="button-addon2">Send</button>
                </div>
              </div>
            </form>
          </div> -->
      </div>
      <div class="pt-5">
        <p style="font-size: 24px; font-weight: 500; letter-spacing: 2px">
          Let's the digital journey begin ! !
        </p>
      </div>

      <div class="row pt-5 mt-5 text-center">
        <div class="col-md-12">
          <div class="border-top pt-5">
            <p style="font-size: 20px; font-weight: 500">
              Copyright &copy;2020 All rights reserved | S-SOFIN
              <i
                class="icon-heart"
                aria-hidden="true"
                style="color: #ffce00"
              ></i>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mb-4 {
  color: #ffce00;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
}

footer a,
footer a:active {
  color: #737373;
}
</style>
