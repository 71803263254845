<template>
  <div class="site-wrap">
    <Header />

    <div class="site-blocks-cover" style="background-color: #ffce00; height: auto">
      <img class="hero-banner-home" src="@/assets/work-background.jpg" />
      <img class="hero-banner-mobile" src="@/assets/mobile-work-background.jpg" />
    </div>

    <section class="site-section">
      <div class="container">
        <div class="row">
          <h2 class="h1 site-section-heading text-center work-heading" style="color: #000000">ONBOARDING</h2>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/Freemium.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/Freemium.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>D-Gift</h2>
                <span class="category"> Digital Gift Card </span>
              </div>
              <p class="label-text">Digital Gift Card</p>
            </a>
          </div>
        </div>

        <div class="row">
          <h2 class="h1 site-section-heading text-center work-heading" style="color: #000000">ACQUISITION</h2>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/LeadGen.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/LeadGen.png" alt="Image" class="img-fluid" style="background-color: white" />
              <div class="media-1-content">
                <h2>Lead Generation</h2>
                <span class="category"> Platform to generate new leads </span>
              </div>
              <p class="label-text">Lead Generation</p>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/D-Loan.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/D-Loan.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Micro Loan</h2>
                <span class="category"> Platform for online retail loans </span>
              </div>
              <p class="label-text">Micro Loan</p>
            </a>
          </div>
        </div>

        <div class="row">
          <h2 class="h1 site-section-heading text-center work-heading" style="color: #000000">NURTURING</h2>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/DemoLeadNurturing.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/LeadNurturing.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Lead Nurturing</h2>
                <span class="category"> Platform to nurture existing leads </span>
              </div>
              <p class="label-text">Lead Nurturing</p>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/cheenoi.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/little-nun.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Little Nun Chatbot</h2>
                <span class="category"> Little Nun Chatbot for consulting </span>
              </div>
              <p class="label-text">Little Nun Chatbot</p>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/Satima.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/satima.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Satima Chatbot</h2>
                <span class="category"> Satima Chatbot for location guidance </span>
              </div>
              <p class="label-text">Satima Chatbot</p>
            </a>
          </div>
        </div>

        <div class="row">
          <h2 class="h1 site-section-heading text-center work-heading" style="color: #000000">SALES PLATFORM</h2>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/SalePlatform.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/SalePlatform.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Digital Sale Platform</h2>
                <span class="category"> Platform for online sales </span>
              </div>
            </a>
            <p class="label-text">Digital Sale Platform</p>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/PolicyLoan.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/Product-JaoKrom.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Policy Loan</h2>
                <span class="category"> Platform for online insurance policy loan </span>
              </div>
              <p class="label-text">Policy Loan</p>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/CarOnline.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/CarOnline.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Market place for Second hand cars</h2>
                <span class="category"> Platform for sale car online with VR Gallery </span>
              </div>
              <p class="label-text">Market place for Second hand cars</p>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/bop-insurance.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/bop-insurance.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>BOP Product card for Insurance</h2>
                <span class="category"> Platform for sharing and sale for online Insurance </span>
              </div>
              <p class="label-text">Sharing and sale for online Insurance</p>
            </a>
          </div>

          <!--
        <div class="col-md-6 col-lg-4 mb-4 work-heading">
          <a href="https://www.s-sofin.com/chappy_final.mp4" target="_blank" class="media-1">
            <img src="@/assets/products/chappy.png" alt="Image" class="img-fluid">
            <div class="media-1-content">
              <h2> Chappy Chatbot </h2>
              <span class="category"> Chappy Chatbot for insurance broker </span>
            </div>
            <p class="label-text"> Chappy Chatbot </p>
          </a>
        </div>
-->
        </div>

        <div class="row">
          <h2 class="h1 site-section-heading text-center work-heading" style="color: #000000">AUTOMATION</h2>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/final_workie20200414.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/workie.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Workie</h2>
                <span class="category"> Work From Home Application </span>
              </div>
              <p class="label-text">Workie</p>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/Beep.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/beep.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Beep</h2>
                <span class="category"> LINE Beacon Application for check-in / check-out </span>
              </div>
              <p class="label-text">Beep</p>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/Blip.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/blip.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Blip</h2>
                <span class="category"> QR Code Application for check-in / check-out </span>
              </div>
              <p class="label-text">Blip</p>
            </a>
          </div>
        </div>

        <div class="row">
          <h2 class="h1 site-section-heading text-center work-heading" style="color: #000000; padding: 0 10px">OPERATION MANAGEMENT PLATFORM</h2>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://training.s-sofin.com/caronline/" target="_blank" class="media-1">
              <img src="@/assets/products/CarOnline-Training.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Operation Management Platform</h2>
                <span class="category"> Operation Management Platform for Automotives </span>
              </div>
              <p class="label-text">Operation Platform for Automotives</p>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 mb-4 work-heading">
            <a href="https://www.s-sofin.com/D-Loan-backoffice.mp4" target="_blank" class="media-1">
              <img src="@/assets/products/D-LOAN-BACKOFFICE.png" alt="Image" class="img-fluid" />
              <div class="media-1-content">
                <h2>Operation Management Platform</h2>
                <span class="category"> Operation Management Platform for Micro Loan </span>
              </div>
              <p class="label-text">Operation Platform for Micro Loan</p>
            </a>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { getPosts } from "../api/posts";

export default {
  props: ["post"],
  name: "Blog",
  data: () => ({
    posts: [],
  }),
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.asyncData();
  },
  methods: {
    async asyncData() {
      const result = await getPosts();
      console.log(result);
      this.posts = result;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text) {
        return text.substring(0, length) + suffix;
      }
    },
  },
};
</script>

<style lang="scss">
.work-heading {
  margin-left: auto;
  margin-right: auto;
}

.img-fluid {
  width: 100%;
  max-height: 250px;
  background-color: #ffce00;
  width: auto;
  border-radius: 20px;
}

.label-text {
  font-size: 24px;
  padding: 1rem 0;

  @media (min-width: 576px) {
    display: none;
  }
}
</style>
