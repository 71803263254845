import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        totalTvCount: 10,
        isLarryHappy: true,
        isJennyHappy: true,
        locale_falg: process.env.BASE_URL + "images/flag/th.svg?v=1",
        locale_falgen: process.env.BASE_URL + "images/flag/en.svg?v=1",
        locale: "th",
        ENDPOINT_DIR: process.env.BASE_URL
    },

    getters: {
        happyStaff: state => {
            return state.isLarryHappy && state.isJennyHappy
        }
    },

    actions: {

        setLocal(context, local) {
            context.commit('setLocal', local)
        }
    },

    mutations: {

        setLocal(state, local) {
            state.locale = local
        }
    }

});
