<template>
  <div>
    <div class="site-mobile-menu">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>

    <header class="site-navbar bg-warning" role="banner">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-11 col-xl-2">
            <a href="/" class="h1 mb-0 nav-text">
              <img alt="logo" class="logo" src="@/assets/logo2.png" />
            </a>
          </div>
          <div class="col-12 col-md-10 d-none d-xl-block">
            <nav
              class="site-navigation position-relative text-right"
              role="navigation"
            >
              <ul class="site-menu js-clone-nav mr-auto d-none d-lg-block">
                <router-link
                  tag="li"
                  active-class="active nav-text"
                  to="/"
                  exact
                  ><a>{{ $t("nav.home") }}</a></router-link
                >
                <router-link tag="li" active-class="active nav-text" to="/Work"
                  ><a>{{ $t("nav.work") }}</a></router-link
                >
                <router-link tag="li" active-class="active nav-text" to="/Blog"
                  ><a>{{ $t("nav.blog") }}</a></router-link
                >
                <router-link tag="li" active-class="active nav-text" to="/Vlog"
                  ><a>{{ $t("nav.vlog") }}</a></router-link
                >
                <router-link
                  tag="li"
                  active-class="active nav-text"
                  to="/Contact"
                  ><a>{{ $t("nav.contact") }}</a></router-link
                >
                <li class="nav-item">
                  <a v-if="$i18n.locale === 'th'" class="nav-link">
                    <img
                      alt="th-flag"
                      class="imgflag"
                      :src="locale_falg"
                      v-on:click="changelanguage('th', $i18n.locale)"
                    />
                  </a>
                  <a v-if="$i18n.locale === 'en'" class="nav-link">
                    <img
                      alt="en-flag"
                      class="imgflag"
                      :src="locale_falgen"
                      v-on:click="changelanguage('en', $i18n.locale)"
                    />
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div
            class="d-inline-block d-xl-none ml-md-0 mr-auto py-3"
            style="position: relative; top: 3px"
          >
            <a href="#" class="site-menu-toggle js-menu-toggle text-black"
              ><span class="icon-menu h3"></span
            ></a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    msg: String,
    selected: undefined,
  },
  data: () => ({
    langs: ["th", "en"],
    // root: this.$store.state.ENDPOINT_DIR,
  }),
  mounted() {},
  computed: {
    locale_falg() {
      return this.$store.state.locale_falg
    },
    locale_falgen() {
      return this.$store.state.locale_falgen
    },
    locale() {
      return this.$store.state.locale
    },
  },
  methods: {
    changelanguage: function (local18, local18_t) {
      this.$i18n.locale = local18 == "th" ? "en" : "th"
      this.$store.dispatch("setLocal", this.$i18n.locale)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.nav-text {
  font-size: 20px;
}

.imgflag {
  width: 20px;
}

.active {
  background: yellow;
}

.logo {
  width: 100px;
  margin-left: -60%;
}
</style>
